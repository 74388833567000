

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #000
}

body {
   font-family: "Montserrat", Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
}