
.projectItem:hover {
    box-shadow: 0px 1px 5px #BB86FC;
    transition: 0.3s ease-in;
    cursor: pointer;
  }
.bgImage {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.projectItem h1 {
    text-align: center;
    font-size: 25px;
}
 
.projectList {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;

    padding: 5%;
}
.projectItem {
    width: 300px;
    height: 300px;
    box-shadow: rgba(255, 255, 255, 0.19) 0px 5px 10px,#03DAC5 0px 2px 2px;
    border-radius: 40px;
    margin: auto;
    align-items: center;
}
.projects {
    color: white;
    justify-content: center;
}
.projects h1 {
    text-align: center;
    margin-top: 8px;
}
.project {
    color: aliceblue;
    margin: auto;
    width: 100%;
    text-align: center;
    height: auto;
}
.project h1 {
    text-align: center;
    margin-block: 25px ;
}
.project img {
    max-height: 65vh;
    align-self: center;
    border-radius: 20px;
    box-shadow: -2px 0 #03DAC5, 0 2px #03DAC5, 2px 0 #03DAC5, 0 -2px #03DAC5;
}
.project h3 {
    margin-block: 10px;
    font-size: 40px;
    width: auto;
    text-align: center;
}
.project p {
    margin-block: 20px;;
}
.project a {
    size: 30px;
}

@media only screen and (max-width: 600px) {
    .projectItem h1 {
        font-size: 20px;
    }
    .project h3 {
        font-size: 20px;
    }
    .project img {
        width: 90vw;
        align-self: center;
        box-shadow: -1px 0 #03DAC5, 0 1px #03DAC5, 1px 0 #03DAC5, 0 -1px #03DAC5;
    }
     
    .projectList {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    .projectItem {
        width: 200px;
        height: 200px;
    }
    .bgImage {
        width: 100%;
        height: 140px;
    }
  }

/* background: '#2f2f2f', color: '#03DAC5'  */