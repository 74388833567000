@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

* {
  padding: 0;
  margin: 0;
}

.about {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about h1 {
  font-size: 45px;
  animation: 2s anim-lineUp ease-out;
  text-align: center;
  color: rgb(202, 202, 202);
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

.prompt {
  font-family: "Montserrat Medium";
  max-width: 40ch;
  text-align: center;
  
  /* transform: scale(0.94); */
  color: #999;
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.prompt span {
  transform: scale(1.5);
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  margin-inline: 9px;
  margin-block: 5px;
  
}

span:nth-child(1) {
  animation: fade-in 1s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 1s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
    animation: fade-in 1s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 1s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 1s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}


.skills {
  margin-top: 30px;
  height: auto;
  width: 100vw;
  justify-content: center;
  color: rgb(202, 202, 202)
}
.skills h1 {
    text-align: center;
    font-size: 40px;
}

.skillList {
    display: grid;
    /* display: inline-grid; */
    grid-template-rows: auto auto auto;
    /* grid-template-columns: auto auto auto; */
    /* grid-template-rows: 100px 100px 100px; */
    /* grid-template-columns: auto-fit 100px 100px; */
    /* grid-template-columns: auto; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* justify-content: center;
    align-self: center; */
    padding: 5%;
 
}

.skillItem {
    display: inline;
    width: 200px;
    height: 200px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin: auto;
    margin-block: 20px;
    align-items: center;
}

.skillItem h1 {
    text-align: center;
    font-size: 25px;
}

.skillImage {
    width: 100%;
    height: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.aboutMe {
  background-color: #2a2a2a;
  height: auto;
  color: rgb(202, 202, 202);
  padding-inline: 20vw;
  padding-block: 10vh;
}
.aboutSelf {
  /* margin: 15vw; */

  margin-bottom: 30px;
}
.lookingFor {
  /* margin: 15vw; */
  margin-block: 30px;
}
.line {
  /* background-color: #999; */
  background-color: #03DAC5;
  /* color: #03DAC5; */
  width: 40px;
  height: 1px;
}
.aboutMe p {
  font-size: 20px;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .skillItem {
    width: 100px;
    height: 100px;
  }
  .skillList {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
